import * as React from 'react';

const SelmerLogo: React.FC = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 378.4 94.9">
    <path
      d="M351.9,64.8V30.3l-16.6,3.6c3.6,4.4,3.6,8.8,3.6,19.1v11.8c0,8.6,0,17-2.4,25.4h17.8C351.9,81.8,351.9,73.4,351.9,64.8z
	 M375.3,30c-10.1-0.1-13.7,5.8-18.7,17.5c6.8-1.9,13.2-2.5,18.7-1.8V30z M325.1,76.6c-21.2,8.7-42.1,4-42.1-21.7
	c0-11.9,4.6-21,11.2-24.3c-9.5,1.4-22.8,12-22.8,30.8c0,15.9,11,29.9,28.6,29.9C308.8,91.3,318.7,87.4,325.1,76.6z M299,30.2
	c8.1,3.8,11.9,10.2,11.9,20.6l-23.1,5.1h37.3C325.3,44.2,317.6,30.7,299,30.2z M226.2,38.4c12.2-1.8,19,0.1,19,11.9v14.5
	c0,8.6,0,17-2.4,25.4h17.8c-2.4-8.4-2.4-16.9-2.4-25.4V45.9c0-8.6-5.2-15.9-15-15.9C237.2,30,231.1,33.8,226.2,38.4z M184.6,64.8
	V30.3L168,33.9c3.6,4.4,3.6,8.8,3.6,19.1v11.8c0,8.6,0,17-2.4,25.4H187C184.6,81.8,184.6,73.4,184.6,64.8z M189.4,38.4
	c12.2-1.8,19,0.1,19,11.9v14.5c0,8.6,0,17-2.4,25.4h17.8c-2.4-8.4-2.4-16.9-2.4-25.4V45.9c0-8.6-5.2-15.9-15-15.9
	C200.3,30,194.2,33.8,189.4,38.4z M134.7,8.9c3.6,3.8,3.6,7.7,3.6,16.6v39.2c0,8.6,0,17-2.4,25.4h17.8c-2.4-8.4-2.4-16.9-2.4-25.4V3
	L134.7,8.9z M124.6,76.6c-21.2,8.7-42.1,4-42.1-21.7c0-11.9,4.6-21,11.2-24.3c-9.5,1.4-22.8,12-22.8,30.8
	c0,15.9,11.1,29.9,28.6,29.9C108.3,91.3,118.2,87.4,124.6,76.6z M98.4,30.2c8.1,3.8,11.9,10.2,11.9,20.6l-23.1,5.1h37.3
	C124.8,44.2,117.1,30.7,98.4,30.2z M34.4,58C23.1,42.4,18.8,34.2,18.8,24.6c0-9.7,5.3-14.6,12.4-14.6c7.4,0,14.5,5.5,24.4,15.6
	L54.1,8.9c-6.4-1.3-15.6-3-22.5-3c-15.1,0-27.3,8-27.3,20.6C4.3,37.7,12.5,46.5,34.4,58z M30.8,35.4c11.4,15.8,15.6,24.7,15.6,33.4
	c0,13-6.8,18.4-14.3,18.4c-10.8,0-17-8-28.5-21.4L5,86.1c7.7,3,17.2,5.2,26.6,5.2c18.3,0,29.7-11.2,29.7-24.2
	C61.4,56,53.3,46.4,30.8,35.4z"
    />
  </svg>
);

export default SelmerLogo;
